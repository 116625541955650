import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISystemMenu,ISystemMenuQuery} from "@/apis/systemManage/menu/types";

/**
 * 分页查询
 * @param params 查询参数
 */
export const systemMenuQueryApi = (params: ISystemMenuQuery) => get<ISystemMenu[]>("/mall/systemMenu/getSystemMenuList",params)

/**
 * 创建
 * @param params 查询参数
 */
export const systemMenuCreateApi = (params: ISystemMenu) => postJ<ISystemMenu>("/mall/systemMenu/create", params)

/**
 * 修改
 * @param params 查询参数
 */
export const systemMenuModifyApi = (params: ISystemMenu) => postJ<ISystemMenu>("/mall/systemMenu/modify", params)


/**
 * 删除
 * @param params 查询参数
 */
export const systemMenuRemoveApi = (params: any) => postJ("/mall/systemMenu/remove", params)



/**
 * 查询详情
 * @param params 查询参数
 */
export const systemMenuDetailApi = (id: string) => get(`/mall/systemMenu/detail/${id}`)
