
import {Component, Vue} from "vue-property-decorator";
import {
  systemMenuCreateApi,
  systemMenuModifyApi,
  systemMenuQueryApi,
  systemMenuRemoveApi
} from "@/apis/systemManage/menu";
import {ICrudOption} from "@/types/m-ui-crud";
import Icons from "@/constants/icon"
import {ISystemMenu, ISystemMenuQuery} from "@/apis/systemManage/menu/types";
/**
 * @name: 系统管理-菜单管理
 * @author: lili
 * @date: 2023-07-19 10:08
 * @description： 系统管理-菜单管理
 * @update: 2023-07-19 10:08
 */
@Component({})
export default class MenuPage extends Vue {
  // crud配置
  crudOption: ICrudOption = {
    dialogWidth: "600px",
    addTitle: "新增菜单",
    editTitle: "编辑菜单",
    searchBox: false,
    menuWidth: 250,
    column: [
      {
        label: "父级菜单",
        prop: "pid",
        hide: true,
        span: 12,
        type: 'select',
      },
      {
        label: "菜单名称",
        prop: "title",
        align: "left",
        search:true,
        width: 200,
        span: 12,
        rules: [
          {required: true, message: '请输入菜单名称', trigger: 'blur'}
        ]
      },
      {
        label: "路由名称",
        prop: "name",
        align: "center",
        width: 200,
        span: 12,
        rules: [
          {required: true, message: '请输入路由名称', trigger: 'blur'}
        ]
      },
      {
        label: "图标",
        prop: "icon",
        align: "center",
        slot: true,
        width: 150,
        span: 12,
        rules: [
          {required: true, message: '请输入菜单图标', trigger: 'blur'}
        ],
        type: "select",
        dicData: Icons.map(item => {
          return {
            label: item, value: item
          }
        })
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
        width: "100",
        span: 12,
        rules: [
          {required: true, message: '请输入排序', trigger: 'blur'}
        ]
      },
      {
        label: "访问路径",
        prop: "path",
        align: "center",
        hide:true,
        width: 300,
        span: 12,
        rules: [
          {required: true, message: '请输入访问路径', trigger: 'blur'}
        ]
      },
      {
        label: "组件路径",
        prop: "component",
        align: "center",
        width: 300,
        span: 12,
        rules: [
          {required: true, message: '请输入组件路径', trigger: 'blur'}
        ]
      },
      {
        label: "菜单类型",
        prop: "menuType",
        align: "center",
        width: "100",
        search:true,
        span: 12,
        type: "select",
        dicData: [
          {
            label: '目录',
            value: 'M'
          },
          {
            label: '菜单',
            value: 'C'
          },
          // {
          //   label: '按钮',
          //   value: 'A'
          // }
        ],
        rules: [
          {required: true, message: '请选择菜单类型', trigger: 'blur'}
        ]
      },
      {
        label: "状态",
        prop: "isHide",
        align: "center",
        width: "100",
        type: "switch",
        addHide: true,
        editHide: true,
        slot:true,
        value: true,
        dicData: [
          {
            label: "",
            value: false,
          },
          {
            label: "",
            value: true,
          },
        ],
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        width: "150",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
    ],
  };
  // 新增/编辑 表单
  form: ISystemMenu ={} ;
  // 列表
  data: ISystemMenu[] = [];
  // 查询条件
  query: ISystemMenuQuery = {}

  /**
   * 查询列表
   */
  getList() {
    systemMenuQueryApi(this.query).then((e) => {
      this.data = e;
      if (this.crudOption.column) {
        this.crudOption.column[0]['dicData'] = e.map((item: any) => {
          return {
            label: item.title,
            value: item.id
          }
        })
      }
    });
  }

  /**
   * 切换状态
   */
  handleSwitch(row: any, val: boolean) {
    systemMenuModifyApi({ id: row.id, isHide: val }).then((e) => {
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      this.getList();
    });
  }

  handleCheck(nodeObj: any, SelectedObj: any) {
    this.form.menuIdList = SelectedObj.checkedKeys;
  }

  /**
   * 新增
   */
  rowSave(form: any, done: Function, loading: Function) {
    form.isHide = false
    if (!form.pid) {
      form.pid = 0
    }
    systemMenuCreateApi(form)
      .then((e) => {
        done();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.getList();
      })
      .finally(() => {
        loading();
      });
  }

  /**
   * 新增
   */
  rowEdit(form: any, done: Function, loading: Function) {
    systemMenuModifyApi(form)
      .then((e) => {
        done();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.getList();
      })
      .finally(() => {
        loading();
      });
  }

  /**
   * 删除
   */
  rowDel(row: any, index: number) {
    systemMenuRemoveApi(row.id).then((e) => {
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      this.getList();
    });
  }

  mounted() {
    this.getList();
  }
}
